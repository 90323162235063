
import { mapGetters } from 'vuex'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination])
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            articleItemWidth: 280,
            articleItemHeight: 0,
            checkInterest: false,
        }
    },
    computed: {
        ...mapGetters({
            featuredContent3: 'cms/featuredContent3',
        }),
    },
    methods: {
        onIntersect(entries, observer) {
            const interesting = entries[0]?.isIntersecting
            if (interesting) {
                this.checkInterest = true
            }
        },
        onNext() {
            const elParent =
                document.getElementsByClassName('w-featured3-slider')
            if (!elParent || !elParent[0]) return
            const elNext =
                elParent[0].getElementsByClassName('swiper-button-next')
            if (!elNext || !elNext[0]) return
            elNext[0].dispatchEvent(new MouseEvent('click'))
        },
        onBack() {
            const elParent =
                document.getElementsByClassName('w-featured3-slider')
            if (!elParent || !elParent[0]) return
            const elBack =
                elParent[0].getElementsByClassName('swiper-button-prev')
            if (!elBack || !elBack[0]) return
            elBack[0].dispatchEvent(new MouseEvent('click'))
        },
        getArticleContentWidth() {
            const client = process.client
            if (this.globalIsMobileOnly) return
            if (client) {
                // Get article content width when size change
                const val = this.$refs?.refFeaturedContent3?.clientWidth
                if (val) {
                    // Display only 4 card if more than that should click arrow right icon above
                    this.articleItemWidth = Math.ceil(val / 4) - 14
                }
            } else {
                setTimeout(() => {
                    this.getArticleContentWidth()
                }, 1000)
            }
            this.articleItemHeight = Math.floor(
                (this.articleItemWidth * 250) / 455
            )
        },
    },
}
