
import { mapGetters } from 'vuex'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Navigation, Pagination])
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            winnerItemWidth: 250,
            checkInterest: false,
        }
    },
    computed: {
        ...mapGetters({
            recentWinner: 'cms/recentWinner',
            topWinner: 'cms/topWinner',
            unluckyWinner: 'cms/unluckyWinner',
        }),
    },
    watch: {
        recentWinner(value) {
            // to display the card only 4, after getting the cms data
            if (value && value?.is_visible) {
                setTimeout(() => {
                    this.getWinnerContentWidth()
                }, 400)
            }
        },
    },
    mounted() {
        // to display the card only 4
        if (this.recentWinner && this.recentWinner?.is_visible) {
            setTimeout(() => {
                this.getWinnerContentWidth()
            }, 400)
        }
    },
    methods: {
        onIntersect(entries, observer) {
            const interesting = entries[0]?.isIntersecting
            if (interesting) {
                this.checkInterest = true
            }
        },
        onNext() {
            const elParent = document.getElementsByClassName(
                'w-recent-winner-slider'
            )
            if (!elParent || !elParent[0]) return
            const elNext =
                elParent[0].getElementsByClassName('swiper-button-next')
            if (!elNext || !elNext[0]) return
            elNext[0].dispatchEvent(new MouseEvent('click'))
        },
        getWinnerContentWidth() {
            const client = process.client
            if (this.globalIsMobileOnly) return
            if (client) {
                // Get winner content width when size change
                const val = this.$refs?.refWinner?.clientWidth
                if (val) {
                    // Display only 4 card if more than that should click arrow
                    this.winnerItemWidth = Math.ceil(val / 4) - 8
                }
            } else {
                setTimeout(() => {
                    this.getWinnerContentWidth()
                }, 1000)
            }
        },
    },
}
