import { render, staticRenderFns } from "./MainCard.vue?vue&type=template&id=307ee7ec"
import script from "./MainCard.vue?vue&type=script&lang=js"
export * from "./MainCard.vue?vue&type=script&lang=js"
import style0 from "./MainCard.vue?vue&type=style&index=0&id=307ee7ec&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme2DesktopHomeSlider: require('/var/app/components/Theme2/Desktop/HomeSlider.vue').default,Theme2DesktopVideoContent1And2: require('/var/app/components/Theme2/Desktop/VideoContent1And2.vue').default,Theme2DesktopFeaturedContent1: require('/var/app/components/Theme2/Desktop/FeaturedContent1.vue').default,Theme2DesktopJackpotContent: require('/var/app/components/Theme2/Desktop/JackpotContent.vue').default,Theme2DesktopPromotionContent: require('/var/app/components/Theme2/Desktop/PromotionContent.vue').default,Theme2DesktopFeaturedContent2: require('/var/app/components/Theme2/Desktop/FeaturedContent2.vue').default,Theme2DesktopRecentWinners: require('/var/app/components/Theme2/Desktop/RecentWinners.vue').default,Theme2DesktopFeaturedContent3: require('/var/app/components/Theme2/Desktop/FeaturedContent3.vue').default,Theme2DesktopFloatingIconsRankingButton: require('/var/app/components/Theme2/Desktop/FloatingIcons/RankingButton.vue').default,Theme2MobileHomeSlider: require('/var/app/components/Theme2/Mobile/HomeSlider.vue').default,Theme2MobileVideoContent1And2: require('/var/app/components/Theme2/Mobile/VideoContent1And2.vue').default,Theme2MobileFeaturedContent1: require('/var/app/components/Theme2/Mobile/FeaturedContent1.vue').default,Theme2MobileJackpotContent: require('/var/app/components/Theme2/Mobile/JackpotContent.vue').default,Theme2MobilePromotionContent: require('/var/app/components/Theme2/Mobile/PromotionContent.vue').default,Theme2MobileFeaturedContent2: require('/var/app/components/Theme2/Mobile/FeaturedContent2.vue').default,Theme2MobileFeaturedContent3: require('/var/app/components/Theme2/Mobile/FeaturedContent3.vue').default,Popup: require('/var/app/components/Popup.vue').default})
