
import { mapGetters } from 'vuex'
export default {
    name: 'FeatureGame2',
    data() {
        return {
            featureItemWidth: 320,
            imageHeight: 0,
        }
    },
    computed: {
        ...mapGetters({
            featuredContent1: 'cms/featuredContent1',
        }),
        featuredContent() {
            const chunkSize = 6
            const result = []
            for (
                let i = 0;
                i < this.getVisibleItems(this.featuredContent1?.items).length;
                i += chunkSize
            ) {
                const chunk = this.getVisibleItems(
                    this.featuredContent1?.items
                ).slice(i, i + chunkSize)
                result.push(chunk)
            }

            return result
        },
    },
    methods: {
        getFeatureGameContentWidth() {
            const client = process.client
            if (!this.globalIsMobileOnly) return
            if (client) {
                const wWidth = window.innerWidth
                if (wWidth) {
                    // Display only 2 card if more than that should click arrow right icon above
                    this.featureItemWidth =
                        Math.ceil(
                            wWidth / (wWidth >= 960 ? 3 : wWidth >= 600 ? 2 : 1)
                        ) -
                        12 -
                        (wWidth >= 960
                            ? 20
                            : wWidth >= 600
                            ? 24
                            : wWidth > 390
                            ? 48
                            : 30)
                }
            } else {
                setTimeout(() => {
                    this.getFeatureGameContentWidth()
                }, 1000)
            }
        },
        onNext() {
            window.document.getElementById('csl-feature-game-next').click()
        },
        onResize() {
            const screenWidth = window.innerWidth
            // since the slider size is 600x400, so I have an formula to calculate the size:
            const height = (screenWidth * 400) / 600
            this.imageHeight = height
        },
    },
}
