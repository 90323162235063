
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            promotionItemWidth: 320,
            imageHeight: 0,
        }
    },
    computed: {
        ...mapGetters({
            promotion: 'cms/promotion',
        }),
        promotionContent() {
            const chunkSize = 6
            const result = []
            for (
                let i = 0;
                i < this.getVisibleItems(this.promotion?.items).length;
                i += chunkSize
            ) {
                const chunk = this.getVisibleItems(this.promotion?.items).slice(
                    i,
                    i + chunkSize
                )
                result.push(chunk)
            }

            return result
        },
    },
    methods: {
        onNext() {
            window.document.getElementById('csl-promotion-next').click()
        },
        getPromotionContentWidth() {
            const client = process.client
            if (!this.globalIsMobileOnly) return
            if (client) {
                const wWidth = window.innerWidth
                if (wWidth) {
                    // Display only 2 card if more than that should click arrow right icon above
                    this.promotionItemWidth =
                        Math.ceil(
                            wWidth / (wWidth >= 960 ? 3 : wWidth >= 600 ? 2 : 1)
                        ) -
                        12 -
                        (wWidth >= 960
                            ? 20
                            : wWidth >= 600
                            ? 24
                            : wWidth > 390
                            ? 48
                            : 30)
                }
            } else {
                setTimeout(() => {
                    this.getPromotionContentWidth()
                }, 1000)
            }
        },
        onResize() {
            const screenWidth = window.innerWidth
            // since the slider size is 600x400, so I have an formula to calculate the size:
            const height = (screenWidth * 240) / 600
            this.imageHeight = height
        },
    },
}
