
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            articleItemWidth: 320,
            imageHeight: 0,
        }
    },
    computed: {
        ...mapGetters({
            featuredContent2: 'cms/featuredContent2',
        }),
    },
    methods: {
        onNext() {
            window.document.getElementById('csl-activities-next').click()
        },
        getArticleContentWidth() {
            const client = process.client
            if (!this.globalIsMobileOnly) return
            if (client) {
                const wWidth = window.innerWidth
                if (wWidth) {
                    // Display only 2 card if more than that should click arrow right icon above
                    this.articleItemWidth =
                        Math.ceil(
                            wWidth / (wWidth >= 960 ? 3 : wWidth >= 600 ? 2 : 1)
                        ) -
                        12 -
                        (wWidth >= 960
                            ? 20
                            : wWidth >= 600
                            ? 24
                            : wWidth > 390
                            ? 48
                            : 30)
                }
            } else {
                setTimeout(() => {
                    this.getArticleContentWidth()
                }, 1000)
            }
        },
        onResize() {
            const screenWidth = window.innerWidth
            // since the slider size is 600x400, so I have an formula to calculate the size:
            const height = (screenWidth * 400) / 600
            this.imageHeight = height
        },
    },
}
